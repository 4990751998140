@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

.active-cardio{
  /*  background: #7f7fd5 !important;*/
  background: transparent !important;
  /*  background: linear-gradient(to left, #91eae4, #86a8e7, #7f7fd5) !important;*/
  background: transparent !important;
  border: 1px solid #33c3f0;

}

.pricing-table {
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
  display: flex;
}
.pricing-table .cardio {
  margin-top: 20px;
  cursor: pointer;
  min-width: 220px;
  height: 570px;
  padding: 30px;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 46px -4px rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;

  background: #00b09b;
  background: linear-gradient(to right, #96c93d, #00b09b);
}
.pricing-table .cardio:nth-child(1) {
  
}
.pricing-table .cardio:nth-child(1) .price::before {
  content: "%";
}
.pricing-table .cardio:nth-child(2) {

  /*background: #ff416c;
  background: linear-gradient(to right, #ff4b2b, #ff416c);*/
}
.pricing-table .cardio:nth-child(2) .price::before {
  content: "%";
}
.pricing-table .cardio:nth-child(3) {
  /*background: #00b09b;
  background: linear-gradient(to right, #96c93d, #00b09b);*/
}
.pricing-table .cardio:nth-child(3) .price::before {
  content: "$100";
}
.pricing-table .cardio:nth-child(1) .price, .pricing-table .cardio:nth-child(3) .price {
  font-size: 6rem;
}
.pricing-table .cardio:nth-child(1) .buy-button, .pricing-table .cardio:nth-child(3) .buy-button {
  width: 230px;
  height: 230px;
}
.pricing-table .cardio:nth-child(1) .buy-button h3, .pricing-table .cardio:nth-child(3) .buy-button h3 {
  top: 12%;
  left: 10%;
  font-size: 1rem;
}
.pricing-table .cardio .type {
  margin-top: 30px;
  letter-spacing: 0.1rem;
}
.pricing-table .cardio .price {
  font-size: 7.5rem;
  position: relative;
  margin: 10px 0px 20px;
/*  z-index: 2;*/
}
.pricing-table .cardio .price span {
  font-size: 1.8rem;
  position: absolute;
  left: -15%;
  top: 65%;
}
.pricing-table .cardio .price::before {
  position: absolute;
  content: "";
  color: rgba(255, 255, 255, 0.06);
  font-size: 9.5rem;
  z-index: -1;
  right: -30%;
  bottom: 15%;
  text-shadow: 0 0 0px rgba(51, 55, 69, 0);
  transition: all 1s ease-in-out;
}
.pricing-table .cardio .plan {
  font-size: 1.3rem;
  position: relative;
  margin-bottom: 10px;
}
.pricing-table .cardio .plan::before, .pricing-table .cardio .plan::after {
  position: absolute;
  content: "";
  width: 35px;
  height: 2px;
  background: #fff;
  bottom: 40%;
  transition: all 0.2s ease-out;
}
.pricing-table .cardio .plan::before {
  right: 100%;
  transform: translate(-50%, -50%);
}
.pricing-table .cardio .plan::after {
  right: -100%;
  transform: translate(0%, -50%);
}
.pricing-table .cardio .details {
  text-transform: capitalize;
}
.pricing-table .cardio .details li {
  margin: 15px 0px;
}
.pricing-table .cardio .buy-button {
  cursor: pointer;
  position: absolute;
  width: 250px;
  height: 230px;
  background: #fff;
  border-radius: 15%;
  right: -34%;
  bottom: -27%;
  transition: all 0.4s ease-in-out;
}
.pricing-table .cardio .buy-button h3 {
  text-shadow: 0 0 0;
  text-decoration: none;
  color: #fff;
  position: absolute;
  left: 8%;
  top: 10%;
  color: #333745;
  font-size: 1.2rem;
  transition: all 0.4s ease-in-out;
}
.pricing-table .cardio:hover {
  transform: scale(1.02);
}
.pricing-table .cardio:hover .price::before {
  -webkit-animation: text-hover 1s ease-in-out infinite normal;
          animation: text-hover 1s ease-in-out infinite normal;
}
.pricing-table .cardio:hover .plan::before {
  right: 90%;
}
.pricing-table .cardio:hover .plan::after {
  right: -90%;
}
.pricing-table .cardio:hover .buy-button {
  width: 100%;
  right: 0%;
  border-radius: 0%;
}
.pricing-table .cardio:hover .buy-button h3 {
  left: 50%;
  transform: translate(-50%, 0%);
}

@-webkit-keyframes text-hover {
  0% {
    right: -30%;
  }
  50% {
    right: -37%;
  }
  100% {
    right: -30%;
  }
}

@keyframes text-hover {
  0% {
    right: -30%;
  }
  50% {
    right: -37%;
  }
  100% {
    right: -30%;
  }
}