/* Loader.css */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 99999999999999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Adjust the z-index as needed */
}
